import React from "react";

import { useInView } from "react-intersection-observer";
import { motion, useReducedMotion } from "framer-motion";
import ReactPlayer from "react-player/vimeo";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-cards";

import RisingHeadline from "../components/RisingHeadline";

function ThinkScientifically(props) {
    const shouldReduceMotion = useReducedMotion();
    const [refVideo, inViewVideo] = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const [refMeans, inViewMeans] = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    return (
        <section className="overflow-hidden bg-white text-black z-20">
            <RisingHeadline
                headline="Think scientifically."
                subtext="Misinformation is a global crisis. It constrains and divides, distorting knowledge with political power. 
                        We believe that social science can be the foundation of a more innovative, more equitable, and more compassionate world."
            />
            <div className="flex flex-col md:flex-row w-full">
                <motion.div
                    ref={refVideo}
                    className="flex-1 w-full px-7 bg-grey-300 my-6"
                    initial={{ x: "-50%", opacity: 0 }}
                    animate={{
                        x: shouldReduceMotion ? 0 : inViewVideo ? 0 : "-50%",
                        opacity: inViewVideo ? 1 : 0,
                    }}
                    transition={{ duration: 1 }}
                >
                    {/* <Vimeo video="314705674" responsive /> */}
                    <div className="player-wrapper relative pt-[56.25%] bg-grey-300">
                        <ReactPlayer
                            url="https://vimeo.com/314705674"
                            controls
                            light
                            playing={true}
                            width="100%"
                            height="100%"
                            className="absolute top-0 left-0"
                            style={{ backgroundColor: "#3333333" }}
                        />
                    </div>
                </motion.div>
                <motion.div
                    ref={refMeans}
                    className="flex-1 px-7 my-6"
                    initial={{ x: "50%", opacity: 0 }}
                    animate={{
                        x: shouldReduceMotion ? 0 : inViewMeans ? 0 : "50%",
                        opacity: inViewMeans ? 1 : 0,
                    }}
                    transition={{ duration: 1 }}
                >
                    <h2 className="text-3xl font-serif">What can social science do to help you achieve your goals?</h2>
                    <ul className="text-xl border-l-2 pl-8 m-4 list-circle">
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                        <li>Vivamus ac nulla ac purus accumsan consequat id vel metus.</li>
                        <li>Suspendisse aliquam ligula nec magna pellentesque tempus.</li>
                        <li>Cras ut enim a massa sollicitudin faucibus.</li>
                        <li>Aenean at eros tristique, sagittis mi nec, vulputate lacus.</li>
                        <li>Etiam id nisi consequat, feugiat magna at, semper nibh.</li>
                    </ul>
                </motion.div>
            </div>
        </section>
    );
}

export default ThinkScientifically;
