import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import LogoText from "../components/LogoText";
import Button from "components/Button";

function IdentityBox(props) {
    return (
        <section className="text-white h-screen md:h-[95vh] md:mt-0 overflow-hidden">
            <div className="h-screen top-0 w-full z-0 fixed" style={{
                    backgroundImage: "radial-gradient( circle farthest-side at 20% 20%, #184a83F0 20%, #0f3b6dF0 60% )",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                }}
            ></div>
            <StaticImage 
                className="z-[-1] w-full h-screen fixed top-0" 
                imgClassName="fixed"
                style={{position: "fixed"}}
                src={"../images/nepal-790335.jpeg"} 
                loading="eager" 
                objectFit="cover"
                formats={["auto", "webp", "avif"]}
                placeholder="none"
            />
            <div className="pt-20 z-10 top-0 fixed md:top-36">
                <div className="mx-auto flex flex-wrap md:flex-nowrap">
                    <LogoText className="text-5xl md:text-7xl m-auto md:mx-0 my-12 md:mb-0 md:p-16 md:pt-0 flex-shrink-0 md:border-r-2" />
                    <div className="text-center md:text-left font-sans font-extralight leading-relaxed md:leading-relaxed md:m-16 self-end flex-shrink-1 m-auto w-[240px] md:w-auto">
                        <p className="text-xl md:text-3xl pb-4">
                            We are a research and teaching cooperative, working globally from Nepal. We help individuals and organizations work more authentically with knowledge.
                        </p>
                        <div className="hidden md:block">
                            <Button>For Students</Button>
                            <Button>For Writers</Button>
                            <Button>For Organizations</Button>
                            <Button>For Travellers</Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default IdentityBox;
