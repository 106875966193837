import React from "react";

function Logo({ className, color }) {
    return (
        <svg
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            alt="OI Logo"
            className={className + " fill-current stroke-current"}
        >
            <title>OI Logo</title>
            <path
                id="OI"
                d="M152.88 43.76 L152.88 158 163.76 158 163.76 43.76 Z M38.32 100.88 C38.32 94.587 39.147 88.453 40.8 82.48 42.453 76.507 45.013 71.147 48.48 66.4 51.947 61.653 56.4 57.84 61.84 54.96 67.28 52.08 73.787 50.64 81.36 50.64 88.933 50.64 95.413 52.08 100.8 54.96 106.187 57.84 110.613 61.653 114.08 66.4 117.547 71.147 120.107 76.507 121.76 82.48 123.413 88.453 124.24 94.587 124.24 100.88 124.24 107.067 123.413 113.173 121.76 119.2 120.107 125.227 117.547 130.613 114.08 135.36 110.613 140.107 106.187 143.92 100.8 146.8 95.413 149.68 88.933 151.12 81.36 151.12 73.787 151.12 67.28 149.68 61.84 146.8 56.4 143.92 51.947 140.107 48.48 135.36 45.013 130.613 42.453 125.227 40.8 119.2 39.147 113.173 38.32 107.067 38.32 100.88 Z M27.44 100.88 C27.44 108.773 28.56 116.293 30.8 123.44 33.04 130.587 36.4 136.88 40.88 142.32 45.36 147.76 50.987 152.107 57.76 155.36 64.533 158.613 72.4 160.24 81.36 160.24 90.32 160.24 98.16 158.613 104.88 155.36 111.6 152.107 117.2 147.76 121.68 142.32 126.16 136.88 129.52 130.587 131.76 123.44 134 116.293 135.12 108.773 135.12 100.88 135.12 92.987 134 85.467 131.76 78.32 129.52 71.173 126.16 64.853 121.68 59.36 117.2 53.867 111.6 49.493 104.88 46.24 98.16 42.987 90.32 41.36 81.36 41.36 72.4 41.36 64.533 42.987 57.76 46.24 50.987 49.493 45.36 53.867 40.88 59.36 36.4 64.853 33.04 71.173 30.8 78.32 28.56 85.467 27.44 92.987 27.44 100.88 Z"
                fillOpacity="1"
                stroke="none"
            />
            <defs>
                <path id="Rectangle" d="M0 200 L200 200 200 0 0 0 Z" />
            </defs>
            <defs>
                <path id="stroke-path" d="M195.5 4.5 L4.5 4.5 4.5 195.5 195.5 195.5 Z" />
            </defs>
            <use
                id="Rectangle-1"
                strokeOpacity="1"
                strokeLinecap="round"
                fill="none"
                strokeWidth="9"
                xlinkHref="#stroke-path"
            />
        </svg>
    );
}

export default Logo;
