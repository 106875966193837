import React from "react";
import { motion, useReducedMotion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function RisingHeadline(props) {
    const shouldReduceMotion = useReducedMotion();
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });
    const [ref2, inView2] = useInView({
        threshold: 1,
        triggerOnce: true,
    });
    return (
        <div className="flex flex-wrap md:flex-nowrap py-20 pb-12">
            <motion.h1
                ref={ref}
                className="text-5xl mx-7 md:flex-shrink-0 font-serif"
                initial={{ y: 100, opacity: 0 }}
                animate={shouldReduceMotion ? {} : { y: inView ? 0 : 100, opacity: inView ? 1 : 0 }}
                transition={{ duration: 0.5 }}
            >
                {props.headline}
            </motion.h1>
            <motion.div
                ref={ref2}
                className="md:border-l-2 text-xl border-current mx-4 md:-my-4 py-4 px-4 font-sans font-light"
                initial={{ opacity: 0 }}
                animate={{ opacity: inView2 ? 1 : 0 }}
                transition={{ duration: 1.5 }}
            >
                {props.subtext}
            </motion.div>
        </div>
    );
}

export default RisingHeadline;

<div className="flex flex-wrap">
    <RisingHeadline>Think scientifically.</RisingHeadline>
</div>;
