import * as React from "react";
import { graphql } from "gatsby";

import { Navigation, Pagination, Scrollbar, A11y, FreeMode, EffectCards, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { useInView } from "react-intersection-observer";
import { motion, useReducedMotion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Header from "../components/Header";
import LogoText from "../components/LogoText";
import RisingHeadline from "../components/RisingHeadline";

import IdentityBox from "../content/IdentityBox";
import ThinkScientifically from "../content/ThinkScientifically";

// markup
const IndexPage = ({ data }) => {
    // const shouldReduceMotion = useReducedMotion();
    // const { scrollY } = useViewportScroll();

    // const initial = 0;
    // const height = useWindowHeight();
    // const yBase = useTransform(scrollY, [initial, height], [initial, height * 0.2]);

    const shouldReduceMotion = useReducedMotion();
    const [refSwiper, inViewSwiper] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    return (
        <Layout>
            <Header textWhite />
            {/* <Hero
                image={data.allFile.edges[0].node.childImageSharp.gatsbyImageData}
                alt="Person looking out over Kathmandu."
            /> */}
            <IdentityBox />
            <main className="relative z-50">
                <ThinkScientifically />

                <section className="bg-black text-white">
                    <RisingHeadline headline="Challenge assumptions." />
                </section>

                <section className="bg-white text-black overflow-hidden">
                    <RisingHeadline headline="Find community." />
                    <motion.div
                        ref={refSwiper}
                        initial={{ x: "50%", opacity: 0 }}
                        animate={{
                            x: shouldReduceMotion ? 0 : inViewSwiper ? 0 : "50%",
                            opacity: inViewSwiper ? 1 : 0,
                        }}
                        transition={{ duration: 1 }}
                    >
                        {/* 
                            SLIDES
                            - analyze data
                            - see the world in new ways
                            - be part of the conversation
                            - 
                        */}
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y, FreeMode, EffectCards, EffectCoverflow]}
                            slidesPerView={"auto"}
                            //centeredSlides={true}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            freeMode={{
                                enabled: true,
                                momentumVelocityRatio: 1,
                                momentumRatio: 0.5,
                            }}
                            className="mySwiper !py-10 md:my-10"
                        >
                            <SwiperSlide className="!h-96 !w-96 !max-w-4/5 grid row-start-1 col-start-1 rounded-xl">
                                <StaticImage
                                    className="z-0 rounded-xl"
                                    layout="constrained"
                                    alt="Man looking out over Kathmandu"
                                    src={"../images/oi_is4.png"}
                                    formats={["auto", "webp", "avif"]}
                                    placeholder="blurred"
                                    width={400}
                                />
                            </SwiperSlide>
                            <SwiperSlide className="!h-96 !w-[36rem] !max-w-4/5 grid row-start-1 col-start-1 rounded-xl">
                                <StaticImage
                                    className="z-0 rounded-xl"
                                    layout="constrained"
                                    alt="Man looking out over Kathmandu"
                                    src={"../images/oi_is3.png"}
                                    formats={["auto", "webp", "avif"]}
                                    placeholder="blurred"
                                    width={400}
                                />
                            </SwiperSlide>
                            <SwiperSlide className="!h-96 !w-96 !max-w-4/5 grid row-start-1 col-start-1 rounded-xl">
                                <StaticImage
                                    className="z-0 rounded-xl"
                                    layout="constrained"
                                    alt="Man looking out over Kathmandu"
                                    src={"../images/oi_is2.png"}
                                    formats={["auto", "webp", "avif"]}
                                    placeholder="blurred"
                                    width={400}
                                />
                            </SwiperSlide>
                            <SwiperSlide className="!h-96 !w-[36rem] !max-w-4/5 grid row-start-1 col-start-1 rounded-xl">
                                <StaticImage
                                    className="z-0 rounded-xl"
                                    layout="constrained"
                                    alt="Man looking out over Kathmandu"
                                    src={"../images/oi_is1.png"}
                                    formats={["auto", "webp", "avif"]}
                                    placeholder="blurred"
                                    width={400}
                                />
                            </SwiperSlide>
                            <SwiperSlide className="!h-96 !w-96 !max-w-4/5 grid row-start-1 col-start-1 rounded-xl">
                                <StaticImage
                                    className="z-0 rounded-xl"
                                    layout="constrained"
                                    alt="Man looking out over Kathmandu"
                                    src={"../images/1.jpg"}
                                    formats={["auto", "webp", "avif"]}
                                    placeholder="blurred"
                                    width={400}
                                />
                            </SwiperSlide>
                            <SwiperSlide className="!h-96 !w-[36rem] !max-w-4/5 grid row-start-1 col-start-1 rounded-xl">
                                <StaticImage
                                    className="z-0 rounded-xl"
                                    layout="constrained"
                                    alt="Man looking out over Kathmandu"
                                    src={"../images/2.jpg"}
                                    formats={["auto", "webp", "avif"]}
                                    placeholder="blurred"
                                    width={400}
                                />
                            </SwiperSlide>
                            <SwiperSlide className="!h-96 !w-[36rem] !max-w-4/5 grid row-start-1 col-start-1 rounded-xl">
                                <StaticImage
                                    className="z-0 rounded-xl"
                                    layout="constrained"
                                    alt="Man looking out over Kathmandu"
                                    src={"../images/3.jpg"}
                                    formats={["auto", "webp", "avif"]}
                                    placeholder="blurred"
                                    width={400}
                                />
                            </SwiperSlide>
                        </Swiper>
                    </motion.div>
                </section>

                <section className="bg-black text-white">
                    <RisingHeadline
                        headline="Empower your passion."
                        subtext="Our courses, workshops, and trainings are designed for leaders in the new knowledge economy.
                        Every program we teach integrates hands-on experience with cutting-edge theoretical insight. We
                        are accredited by Training Qualifications UK, which means that the work you do with us will be recognized 
                        globally."
                    />
                </section>
                <section className="bg-white text-black">
                    <RisingHeadline headline="Come be part." />
                </section>

                <section className="bg-oi-blue text-white">
                    <div>&nbsp;</div>
                </section>
            </main>
            <footer className="py-10 bg-oi-blue-dark text-white z-50 relative">
                <LogoText className="text-5xl m-7" />
            </footer>
        </Layout>
    );
};

// export const heroQuery = graphql`
//     query MyQuery {
//         allFile(filter: { sourceInstanceName: { eq: "images" }, name: { eq: "human-5343120" } }) {
//             edges {
//                 node {
//                     id
//                     name
//                     childImageSharp {
//                         id
//                         gatsbyImageData(formats: [AUTO, WEBP, AVIF], layout: FULL_WIDTH)
//                     }
//                 }
//             }
//         }
//     }
// `;

export const heroQuery = graphql`
    query MyQuery {
        allFile(filter: { sourceInstanceName: { eq: "images" }, name: { eq: "human-5343120" } }) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        id
                        gatsbyImageData(formats: [AUTO, WEBP, AVIF], layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                }
            }
        }
    }
`;

export default IndexPage;
