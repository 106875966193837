import React from 'react';

function Button(props) {
    return (
        <button className="px-6 py-2 transition ease-in duration-200 uppercase m-2 rounded-full hover:bg-gray-800 hover:text-white border focus:outline-none">
            {props.children}
        </button>
    );
}

export default Button;