import React from "react";

function LogoText({ className, ...props }) {
    return (
        <div className={`font-serif + ${className}`} {...props}>
            <span className="leading-snug">
                THE <span className="italic text-[140.6%]">open</span>
            </span>
            <br />
            <span className="leading-none">INSTITUTE</span>
            <br />
            <span className="text-[67.4%] leading-none">for social science</span>
        </div>
    );
}

export default LogoText;
