import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useViewportScroll, useReducedMotion, useTransform } from "framer-motion";
import { useWindowHeight } from "@react-hook/window-size";

import Logo from "components/Logo";
//import "styles/Header.css";

//##################################################################
// TODO:
// - Reduce motion on mobile menu animation (button and slide-in)
//##################################################################

function Header({textWhite = false}) {
    const height = useWindowHeight();

    const shouldReduceMotion = useReducedMotion();
    const { scrollY } = useViewportScroll();

    const [stickyHeader, setStickyHeader] = useState(false);
    const [shrunkHeader, setShrunkHeader] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const scrolledPastTop = useTransform(scrollY, [0, 0.0001], [0, 1]);
    const scrolledPastShrink = useTransform(scrollY, [0, height * 0.1 + 0.0001], [0, 1]);

    useEffect(() => {
        scrolledPastTop.onChange((isPastTop) => {
            setStickyHeader(isPastTop);
        });
    }, [scrolledPastTop]);
    useEffect(() => {
        scrolledPastShrink.onChange((isPastShrink) => {
            setShrunkHeader(isPastShrink === 1 && !shouldReduceMotion);
        });
    }, [scrolledPastShrink, shouldReduceMotion]);

    return (
        <header
            className={
                "fixed bg-transparent h-28 z-30 top-0 md:border-b md:border-opacity-30 md:justify-center md:flex md:items-center w-full" +
                " transition-all duration-500 ease-in-out" +
                (shrunkHeader ? " md:bg-white md:border-oi-grey" : " md:border-transparent") +
                (stickyHeader ? " md:h-20" : "")
            }
        >
            <input type="checkbox" id="menu-toggle" className="hidden peer" checked={menuOpen} onChange={() => setMenuOpen(!menuOpen)} />
            {/* <button> */}
            <label
                id="nav-icon3"
                htmlFor="menu-toggle"
                className={
                    //"block md:hidden z-40 absolute right-8 top-12 cursor-pointer h-6 w-8"
                    "block md:hidden z-40 absolute right-[.75rem] top-[1.75rem] cursor-pointer h-16 w-[4.5rem] border-transparent border-[20px]"
                }
                style={{ WebkitTapHighlightColor: "transparent" }}
            >
                <span className={
                    "top-0 block absolute h-0.5 w-full rounded-md transition-all duration-500 ease-in-out left-0" +
                    ((textWhite && !menuOpen) ? " bg-white" : " bg-oi-grey") +
                    (menuOpen ? " top-[12px] w-0 left-1/2" : "")
                }></span>
                <span className={
                    "top-2 block absolute h-0.5 w-full rounded-md transition-all duration-500 ease-in-out left-0" +
                    ((textWhite && !menuOpen) ? " bg-white" : " bg-oi-grey") +
                    (menuOpen ? " rotate-45" : "")
                }></span>
                <span className={
                    "top-2 block absolute h-0.5 w-full rounded-md transition-all duration-500 ease-in-out left-0" +
                    ((textWhite && !menuOpen) ? " bg-white" : " bg-oi-grey") +
                    (menuOpen ? " rotate-[-45deg]" : "")
                }></span>
                <span className={
                    "top-4 block absolute h-0.5 w-full rounded-md transition-all duration-500 ease-in-out left-0" +
                    ((textWhite && !menuOpen) ? " bg-white" : " bg-oi-grey") +
                    (menuOpen ? " top-[12px] w-0 left-1/2" : "")
                }></span>
            </label>
            {/* </button> */}
            <div className="h-full z-30 relative">
                <Link to="/">
                    <Logo
                        className={
                            "h-full p-6 transition-all duration-500 ease-in-out mx-auto" +
                            (textWhite ? " text-white" : " text-oi-grey") +
                            (menuOpen ? " text-oi-grey" : "") +
                            (shrunkHeader ? " md:text-oi-grey" : " md:text-white") + 
                            (stickyHeader ? " md:py-3" : "")
                        }
                    />
                </Link>
            </div>

            <nav className="md:flex-grow p-0 h-screen md:h-auto max-w-0 md:max-w-full peer-checked:max-w-full w-full z-20 absolute md:static left-0 top-0 overflow-hidden transition-all duration-500 ease-out">
                <ul className={
                        "md:flex h-full md:h-auto p-12 md:p-0 pt-32 md:pt-0 bg-white text-oi-grey md:bg-transparent text-lg" +
                        (shrunkHeader ? "" : " md:text-white") + 
                        (stickyHeader ? " md:py-0" : "")                    
                    }>
                    <li className="px-5 uppercase whitespace-nowrap">
                        <Link to="#">About Us</Link>
                    </li>
                    <li className="px-5 uppercase whitespace-nowrap">
                        <Link to="#">Our Members</Link>
                    </li>
                    <li className="px-5 uppercase whitespace-nowrap">
                        <Link to="#">Our Courses</Link>
                    </li>
                    <li className="px-5 uppercase whitespace-nowrap">
                        <Link to="#">News and Events</Link>
                    </li>
                    <li className="px-5 md:px-0 uppercase flex-grow">
                        <Link to="#">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 md:ml-auto"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <title>Search</title>
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                        </Link>
                    </li>
                    <li className="px-5 uppercase">
                        <Link to="#">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <title>Accounts</title>
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                            </svg>
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
